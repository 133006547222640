import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import kebabCase from "lodash/kebabCase";
import Layout from "../components/Layout";
import Page from "../components/Page";
import RightSidebar from "../components/RightSidebar";
import Sidebar from "../components/Sidebar";


const TagsListTemplate = () => {
  const data = useStaticQuery(query);
  const { title, subtitle, logo } = data.site.siteMetadata;
  const { group } = data.allMarkdownRemark;

  return (
    <Layout title={`すべてのタグ - ${title}`} description={subtitle}>
      <Sidebar />
      <Page logo={logo} title="Tags">
        <ul>
          {group.map((tag) => (
            <li key={tag.fieldValue}>
              <Link to={`/tag/${kebabCase(tag.fieldValue)}/`}>
                {tag.fieldValue} ({tag.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </Page>
      <RightSidebar />
    </Layout>
  );
};

const query = graphql`
  query TagsListQuery {
    site {
      siteMetadata {
        title
        subtitle
        logo {
          title
          subtitle
          path
          width
          height
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { template: { eq: "post" }, draft: { eq: false } }
      }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;

export default TagsListTemplate;
